<template>
	<div style="margin-top: 20px;text-align: center;" class="sameWidth">
		<el-breadcrumb separator-class="el-icon-arrow-right" style="margin:0">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/companyActive' }">公司业务</el-breadcrumb-item>
			<el-breadcrumb-item>招投标培训</el-breadcrumb-item>
		</el-breadcrumb>
		<div style="margin: 50px 0;text-align: center;">
			<h1 class="font2">招投标培训</h1>
			<h1 class="borderBottom"><span>&nbsp;</span></h1>
			<div style="width: 50%;display: inline-block;vertical-align: middle;">
				<img src="../../../assets/images/companyActive/公司业务—培训.jpg" style="width: 100%;"/>
			</div><div style="width: 50%;display: inline-block;vertical-align:top;">
				<p style="text-indent: 2em;padding-left: 20px;font-size: 16px;color: #595959;line-height: 30px;text-align: left;" >
					招投标培训服务是为企事业单位提供各种招标投标培训课程，旨在帮助客户规范招投标管理、降低招投标风险、提高招投标效率、提高成功概率。培训服务分为公益课、公开课、企业内训课。
				</p>
				<p style="text-indent: 2em;margin-top: 30px;padding-left: 20px;font-size: 16px;color: #595959;line-height: 30px;text-align: left;">
					招投研究院成立以来，已经为来自广东、北京、上海、天津、湖南、湖北、四川、山东、广西、云南等全国 500 多家企事业单位了招投标法律法规、政府采购法律法规、企业竞标能力提升、快速提升中标率、高分标书编制及审核等咨询培训服务，取得了良好的社会声誉。
				</p>
			</div>
		</div>
		<div style="margin: 100px 0;text-align: center;">
			<h1  class="font2">服务对象</h1>
			<h1 class="borderBottom"><span>&nbsp;</span></h1>
			<div style="display: flex;text-align: center;">
				<div style="flex: 1;padding: 20px;">
					<i class="iconfont icon-caigourenyuan" style="font-size:80px;color:#409EFF ;"></i>
					<h3 style="color: #595959;">采购人</h3>
				</div>
				<div style="flex: 1;padding: 20px;">
					<i class="iconfont icon-gongyingshang" style="font-size:80px;color:#409EFF ;"></i>
					<h3 style="color: #595959;">供应商</h3>
				</div>
				<div style="flex: 1;padding: 20px;">
					<i class="iconfont icon-jianguanbumen" style="font-size:80px;color:#409EFF ;"></i>
					<h3 style="color: #595959;">监管部门</h3>
				</div>
				<div style="flex: 1;padding: 20px;">
					<i class="iconfont icon-zhuanjia" style="font-size:80px;color:#409EFF ;"></i>
					<h3 style="color: #595959;">专家</h3>
				</div>
			</div>
		</div>
		
		<div style="margin:100px 0;text-align: center;">
			<h1 style="margin-bottom: 50px;" class="font2">服务优势</h1>
			<h1 class="borderBottom"><span>&nbsp;</span></h1>
			<div style="display: flex;">
				<div style="flex: 1;padding:0 5px;" v-for="(item,index) in youshi.slice(0,3)" :key="index">
					<div style="border:#EBEEF5 1px solid;border-radius: 5px;height: 100%;postition: realative;">
						<div style="background-color:#1C88CF;color:#fff;padding:4% 0;">
							<p><i class="iconfont" v-bind:class="item.icon" style="font-size:70px;"></i></p>
							<h2>{{item.title}}</h2>
						</div>
						<p style="padding: 20px;text-align: left;line-height:25px;font-size: 16px;color: #595959;">
							{{item.des}}
						</p>
					</div>
				</div>
			</div>
		</div>
		<div style="margin:20px 0;display: inline-block;text-align: left;">
			<h1 class="font2">经典课程</h1>
			<h1 class="borderBottom"><span>&nbsp;</span></h1>
			<div style="display: flex;">
				<div style="flex: 1;padding:5px;box-sizing: border-box;" v-for="(item,index) in course" :key="index" @click="goTo()">
					<div style="border-radius: 5px;background-color: #1C88CF;color: #fff;padding:5px;">
						<div>
							<img src="../../../assets/images/研究院logo白底.png" style="width:10%;vertical-align: middle;"/>
							<p style="display: inline-block;vertical-align: middle;">招投研究院</p>
							<span style="float: right;margin:18px 50px 0 0;">{{item.num}}</span>
							<h3 style="text-align: center;color:#D6B73A;white-space:nowrap;overflow: hidden;">{{item.title}}</h3>
							<p style="text-align: center;color:#fff;margin-bottom: 20px;font-size: 14px;font-weight: 550;font-family: 微软雅黑;">{{item.desc}}</p>
						</div>
					</div>
					<h3 style="margin-bottom: 5px;text-align: center;">{{item.title}}</h3>
					<p style="color:#909399;font-size: 14px;margin: 0;text-align: center;">
						<span style="margin-right: 20px;">共180分钟</span>
						<span style="margin-right: 20px;">刘春胜</span>
						<span>300+人学过</span>
					</p>
				</div>
			</div>
		</div>
		<div style="margin:100px 0;text-align: center;">
			<h1 class="font2">培训见证</h1>
			<h1 class="borderBottom"><span>&nbsp;</span></h1>
				<el-carousel :interval="3000" arrow="always" style="height: 300px;background-color: #fff;">
					<el-carousel-item>
						<div v-for="(item,index) in zizhi.slice(0,3)" :key="index" style="display:inline-block; width:33%;vertical-align: middle;padding: 0 5px;box-sizing: border-box;">
							<el-image :src="item.src" style="width: 100%;"></el-image>
						</div>
					</el-carousel-item>
					<el-carousel-item>
						<div v-for="(item,index) in zizhi.slice(3,6)" :key="index" style="display:inline-block; width:33%;vertical-align: middle;padding: 0 5px;box-sizing: border-box;">
							<el-image :src="item.src" style="width: 100%;"></el-image>
						</div>
					</el-carousel-item>
				</el-carousel>
		</div>
	</div>
</template>
<script>
export default {
	name:'tenderTrain',
	metaInfo: {
		title: '招投标培训',
		meta: [{
				name: 'keywords',
				content: '招投标培训,招投标能力,竞标,招投标风险,招投标效率'
			},
			{
				name: 'description',
				content: '招投标培训服务是为企事业单位提供各种招标投标培训课程，旨在帮助客户规范招投标管理、降低招投标风险、提高招投标效率、提高成功概率。培训服务分为公益课、公开课、企业内训课。'
			}
		]
	},
	data() {
		return {
			screenWidth:1300,
			course: [
				{
				id: 1,
				num:'第一期',
				title: "企业如何参与政府采购和招投标工作",
				desc:'名师解析 + 实战经验 + 在线答疑 + 进阶提升',
				},
				{
				id: 2,
				num:'第二期',
				title: "提升企业招投标能力培训(电力专场)",
				desc:'现场评估 + 案例解读 + 管理技巧 + 提升策略',
				},
				{
				id: 3,
				num:'第三期',
				title: "企业竞标能力提升培训班",
				desc:'意识篇/规避篇 + 技能篇 + 编制篇 + 提升篇',
				},
			],
			zizhi: [
				{src: require("../../../assets/images/tenderTrain/1.jpg")},
				{src: require("../../../assets/images/tenderTrain/2.jpg")},
				{src: require("../../../assets/images/tenderTrain/3.jpg")},
				{src: require("../../../assets/images/tenderTrain/4.jpg")},
				{src: require("../../../assets/images/tenderTrain/5.jpg")},
				{src: require("../../../assets/images/tenderTrain/6.jpg")},
			],
			youshi:[
				{
					icon:'icon-jingyan',
					title:'行业经验丰富',
					des:'具有11年招投标咨询、培训、招标代理、投标管理、现场评估等丰富经验的团队，亲临指导。'
				},
				{
					icon:'icon-bianzhi',
					title:'案例丰富',
					des:'课程全过程将解读100个以上的废标、投标文件编制案例，通过众多案例加深对招投标的理解。'
				},
				{
					icon:'icon-peixun',
					title:'多维度解读',
					des:'从招标管理、投标管理、招标代理、评标专家、现场评估专家等多维度解读如何快速提升中标率。'
				},
			],
		}
	},
	methods: {
		goTo(){
		}
	},
	mounted:function(){
		this.bus.$emit('headerImg',this.headerImg);
		this.screenWidth=document.documentElement.clientWidth;
		window.addEventListener('resize',()=>{
			this.screenWidth=document.documentElement.clientWidth;
		})
	}
}
</script>

<style scoped>
	.font2{
		text-align: center;
		background-image: -webkit-gradient( linear, left top, right top, color-stop(0.3, #22f), color-stop(0.6, #2ff) );
		color: transparent;
		-webkit-background-clip: text;
	}
	.borderBottom{
		text-align: center;
		margin-top: -40px;
	}
	.borderBottom span{
		border-bottom:#FF8700 3px solid ;
		display: inline-block;
		width: 50px;
	}
</style>
